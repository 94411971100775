<template>
  <div
    class="flex w-full items-center justify-center text-sm italic text-ng300 laptop:items-start laptop:text-lg laptop:text-ng400"
  >
    <p
      class="flex h-[79px] items-center justify-center text-center text-sm capitalize laptop:h-[85px] laptop:text-left laptop:text-lg"
    >
      {{ $t("olive-fruits") }} & {{ $t("seeds") }}
    </p>
    <img
      src="/images/greece-map.svg"
      class="max-h-[63px] max-w-[74px] shrink-[2] laptop:mx-2.5 laptop:max-h-[118px] laptop:max-w-[137px]"
      :alt="$t('greece')"
      width="137"
      height="118"
      loading="lazy"
    />
    <p
      class="flex h-[79px] items-center justify-center text-center text-sm laptop:h-[85px] laptop:text-left laptop:text-lg"
    >
      {{ $t("from-larissa-greece") }}
    </p>
  </div>
</template>
<script setup lang="ts">
const { t: $t } = useI18n();
</script>
<style lang="scss" scoped></style>
