<template>
  <div
    data-speed="0.15"
    ref="parallax"
    class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12"
    id="test"
  >
    <div
      class="col-span-6 col-start-1 mb-[60%] flex flex-col items-center laptop:col-span-6 laptop:col-start-4 min-[2000px]:mb-0"
    >
      <HomepageMiniMapSection class="mb-[176px] laptop:mb-20" />
      <HomepageCertificationsSection />
      <div ref="text1">
        <p
          class="mt-[70px] text-center font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:mt-[165px] laptop:text-[32px]"
        >
          <AnimationsText :text="data?.homePage?.data?.attributes?.quality_title_1" type="words" stagger="0.02" />
          <!--          {{ data?.homePage?.data?.attributes?.quality_title_1 }} <br />-->
        </p>
        <p
          class="mt-12 text-center font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:mt-14 laptop:text-[32px]"
        >
          <AnimationsText
            :text="data?.homePage?.data?.attributes?.quality_title_2"
            type="words"
            stagger="0.02"
            delay=".25"
          />
          <!--          {{ data?.homePage?.data?.attributes?.quality_title_2 }}-->
        </p>
      </div>
      <div>
        <NextBtn class="mt-[40px]" :text="$t('how-to-work-with-us')" :to="localePath({ name: 'for-business' })" />
      </div>
    </div>
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useWaitForPageTransitionEnd } from "~~/composables/waitPageTransition";
import { useParallax } from "~~/composables/useParallax";
import { HomepageDataKey } from "~/symbols/symbols";

const data = inject(HomepageDataKey);

const parallax = ref<HTMLElement | null>(null);

const { promise } = useWaitForPageTransitionEnd();

const localePath = useLocalePath();
const { t: $t } = useI18n();

const text1 = ref<HTMLElement | null>(null);

onMounted(() => {
  initAnimation();
});

async function initAnimation() {
  await promise.value;

  /*if (text1.value) {
    gsap
      .timeline({
        scrollTrigger: {
          trigger: text1.value,
          start: "center 90%",
          scrub: false,
          toggleActions: "play none none none",
        },
      })
      .fromTo(
        text1.value,
        {
          y: 100,
          opacity: 0,
        },
        {
          y: 0,
          opacity: 1,
          duration: 1.5,
          ease: "power4.out",
        }
      );
  }*/

  useParallax(parallax.value);
}

onBeforeUnmount(() => {
  // ScrollTrigger.killAll();
});
</script>
<style lang="scss" scoped></style>
