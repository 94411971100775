import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

export function useParallax(element: HTMLElement | null) {
  let tween;
  if (element) {
    tween = gsap.to(element, {
      scrollTrigger: {
        scrub: true,
      },
      y: (i, target) => -ScrollTrigger.maxScroll(window) * target?.dataset?.speed || 0,
      ease: "none",
    });
  }

  onBeforeUnmount(() => {
    if (tween) {
      tween.kill();
    }
  });
}
