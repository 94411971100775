<template>
  <NuxtLink
    class="group relative flex aspect-square h-full max-h-[290px] w-full max-w-[290px] cursor-pointer items-center justify-center bg-transparent"
    ref="button"
    :to="props.to || '#'"
  >
    <svg
      viewBox="0 0 100 100"
      width="100"
      height="100"
      class="pointer-events-none absolute top-1/2 left-1/2 z-10 h-[145%] w-[145%] -translate-x-1/2 -translate-y-1/2 -rotate-180 fill-ng80 text-[6px] uppercase tracking-widest text-ng80 duration-500 ease-in-out"
      :class="{
        'group-hover:-rotate-90': !(props.overflown || props.overflownMore),
      }"
    >
      <defs><path id="circle" d=" M 50, 50 m -37, 0 a 37,37 0 1,1 74,0 a 37,37 0 1,1 -74,0" /></defs>
      <text>
        <textPath href="#circle" text-anchor="middle" startOffset="50%">{{ props.name }}</textPath>
      </text>
    </svg>
    <div
      class="relative flex aspect-square max-h-[270px] w-full max-w-[270px] flex-col items-center justify-center"
      :class="{
        'my-5 laptop:my-8': props.overflown || props.overflownMore,
      }"
    >
      <div class="flex aspect-square h-full w-full items-center justify-center rounded-full bg-fruit-bg-alt">
        <img
          v-if="props.image"
          class="h-full max-h-[60%] w-full max-w-[60%] transform object-contain duration-500 ease-out"
          :class="{
            'scale-[1.7]': props.overflown,
            'group-hover:scale-[1.8]': props.overflown,
            'scale-[1.8] translate-y-[-5%]': props.overflownMore,
            'group-hover:scale-[1.9] group-hover:translate-y-[-10%]': props.overflownMore,
          }"
          :src="props.image"
          :alt="props.alt"
          loading="lazy"
        />
      </div>
      <div v-if="props.title" class="description absolute top-[calc(100%_+_20px)] laptop:top-[calc(100%_+_32px)]">
        {{ props.title }}
      </div>
    </div>
  </NuxtLink>
</template>
<script lang="ts" setup>
const props = defineProps({
  image: {
    type: String,
    required: false,
  },
  alt: {
    type: String,
    required: true,
  },
  name: {
    type: String,
    required: false,
  },

  to: {
    type: String,
    required: false,
  },
  title: {
    type: String,
    required: false,
  },
  overflown: {
    type: Boolean,
    required: false,
  },
  overflownMore: {
    type: Boolean,
    required: false,
  },
});

const button = ref<HTMLElement | null>(null);

defineExpose({
  button,
});
</script>
<style lang="scss" scoped></style>
