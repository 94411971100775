<template>
  <div class="olive-branch pointer-events-none relative select-none" ref="container" data-speed="-0.2">
    <img
      src="/images/olive-branch-v2.png"
      class="absolute -bottom-[94px] left-0 object-contain z-[40]"
      id="olive-branch"
      ref="oliveBranchDom"
      alt="olive-branch-animation"
      height="584"
      width="762"
    />
    <img
      src="/images/olive-v5.png"
      class="pointer-events-none absolute bottom-[46px] left-[492px] z-50 select-none object-contain"
      id="olive"
      ref="olive"
      alt="olive-branch-animation"
    />
    <div
      class="pointer-events-none absolute bottom-[46px] left-[492px] z-10 select-none object-contain"
      ref="dummyOlive"
    ></div>
    <img
      src="/images/olive-branch-overlay.png"
      class="absolute -bottom-[94px] left-0 object-contain z-[60]"
      id="olive-branch-overlay"
      ref="oliveBranchDom2"
      alt="olive-branch-animation"
      height="584"
      width="762"
    />
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useElementBounding } from "@vueuse/core";
import { useLocaleChanging } from "~~/stores/global";

const props = defineProps<{
  oliveHolder: HTMLElement | null;
}>();
const localeChangingStore = useLocaleChanging();

const olive = ref<HTMLElement | null>(null);
const dummyOlive = ref<HTMLElement | null>(null);
const oliveBranchDom = ref<HTMLElement | null>(null);
const oliveBranchDom2 = ref<HTMLElement | null>(null);

onMounted(() => {
  const unwatch = watch(
    [() => localeChangingStore.pageTransitionEnded, () => props.oliveHolder],
    ([pageTransitionEnded, oliveHolder]) => {
      if (pageTransitionEnded === true && oliveHolder !== null) {
        initAnimation(oliveHolder as HTMLElement);
        unwatch();
      }
    },
    { immediate: true },
  );
});

const container = ref<HTMLElement | null>(null);

let tl: gsap.core.Timeline | null = null;
let parallaxTl: gsap.core.Timeline | null = null;
let parallaxTl2: gsap.core.Timeline | null = null;
const mm = gsap.matchMedia();

function initAnimation(oliveHolder: HTMLElement) {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: oliveBranchDom.value as HTMLElement,
      start: "center center",
      end: "bottom top",
      scrub: 1.8,
      invalidateOnRefresh: true,
      // once: true,
    },
  });

  mm.add("(min-width: 1024px)", () => {
    parallaxTl = gsap.timeline({
      scrollTrigger: {
        trigger: oliveBranchDom.value as HTMLElement,
        start: "center center",
        end: "200% top",
        scrub: true,
        // once: true,
      },
    });
    parallaxTl.to(oliveBranchDom.value as HTMLElement, {
      yPercent: 80,
      ease: "none",
    });

    parallaxTl2 = gsap.timeline({
      scrollTrigger: {
        trigger: oliveBranchDom2.value as HTMLElement,
        start: "center center",
        end: "200% top",
        scrub: true,
        // once: true,
      },
    });
    parallaxTl2.to(oliveBranchDom2.value as HTMLElement, {
      yPercent: 80,
      ease: "none",
    });
  });

  const oliveBranchEl = oliveBranchDom.value as HTMLElement;
  const oliveEl = olive.value as HTMLElement;
  const dummyOliveEl = dummyOlive.value as HTMLElement;
  const oliveHolderEl = oliveHolder as HTMLElement;

  const olivePercentageLeft = 0.64;
  const olivePercentageBottom = 0.23;

  const { width: oliveBranchElWidth, height: oliveBranchElHeight } = useElementBounding(oliveBranchEl);

  // const {
  //   width: oliveElWidth,
  //   height: oliveElHeight,
  //   left: oliveElLeft,
  //   top: oliveElTop,
  // } = useElementBounding(oliveEl);

  const {
    width: doliveElWidth,
    height: doliveElHeight,
    left: doliveElLeft,
    top: doliveElTop,
  } = useElementBounding(dummyOliveEl);

  const {
    width: oliveHolderElWidth,
    height: oliveHolderElHeight,
    left: oliveHolderElLeft,
    top: oliveHolderElTop,
  } = useElementBounding(oliveHolderEl);

  watch(
    [() => oliveBranchElWidth.value, () => oliveBranchElHeight.value],
    ([oliveBranchElWidth, oliveBranchElHeight]) => {
      oliveEl.style.left = `${oliveBranchElWidth * olivePercentageLeft}px`;
      oliveEl.style.bottom = `${oliveBranchElHeight * olivePercentageBottom - 94}px`;
      dummyOliveEl.style.left = `${oliveBranchElWidth * olivePercentageLeft}px`;
      dummyOliveEl.style.bottom = `${oliveBranchElHeight * olivePercentageBottom - 94}px`;
    },
    { immediate: true },
  );

  watch(
    [() => oliveHolderElWidth.value, () => oliveHolderElHeight.value],
    ([oliveHolderElWidth, oliveHolderElHeight]) => {
      oliveEl.style.width = `${oliveHolderElWidth * 0.6}px`;
      oliveEl.style.height = `${oliveHolderElHeight * 0.6}px`;
      dummyOliveEl.style.width = `${oliveHolderElWidth * 0.6}px`;
      dummyOliveEl.style.height = `${oliveHolderElHeight * 0.6}px`;
    },
    { immediate: true },
  );

  tl.fromTo(
    olive.value,
    { x: 0, y: 0, rotation: 0 },
    {
      x: () => oliveHolderElLeft.value - doliveElLeft.value + oliveHolderElWidth.value / 2 - doliveElWidth.value / 2,
      y: () => oliveHolderElTop.value - doliveElTop.value + oliveHolderElHeight.value / 2 - doliveElHeight.value / 2,
      rotation: () => 420,
    },
  );

  /*console.log(tlto);

  useResizeObserver(container, () => {
    console.log("resized");
    if (tl) {
      tlto.clear();

      tlto = tl.to(olive.value, {
        x: () => oliveHolderElLeft.value - oliveElLeft.value + oliveHolderElWidth.value / 2 - oliveElWidth.value / 2,
        y: () => oliveHolderElTop.value - oliveElTop.value + oliveHolderElHeight.value / 2 - oliveElHeight.value / 2,
        rotation: () => 420,
      });
    }
  });*/
  // useParallax(container.value);
}

onBeforeUnmount(() => {
  if (tl) {
    tl.kill();
    // ScrollTrigger.killAll();
  }

  if (parallaxTl) {
    parallaxTl?.kill();
  }

  if (parallaxTl2) {
    parallaxTl2?.kill();
  }

  mm.revert();
});
</script>
<style lang="scss" scoped></style>
