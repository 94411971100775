<template>
  <!--  <img-->
  <!--    src="/images/leafs.png"-->
  <!--    speed="2"-->
  <!--    class="parallaxLeaf absolute inset-0 col-span-2 col-start-5 transform justify-self-center"-->
  <!--    alt=""-->
  <!--    ref="leaf"-->
  <!--    loading="lazy"-->
  <!--  />-->
  <div class="absolute top-0 left-0 w-full h-0 pt-[100%]" ref="parent">
    <canvas
      ref="canvas"
      class="parallaxLeaf absolute top-0 left-0 w-full h-full inset-0 col-span-2 col-start-5 transform justify-self-center"
    ></canvas>
    <img src="/images/homepage/sequence/resized/dirt.png" class="absolute top-0 left-0 w-full h-full" />
  </div>
</template>

<script setup lang="ts">
import { gsap } from "gsap";
import { useWaitForPageTransitionEnd } from "~/composables/waitPageTransition";
const canvas = ref(null);
const parent = ref(null);

const size = useElementSize(parent);

const unwatch = watch([size.width, size.height], () => {
  canvas.value.width = canvas.value.height = size.width.value;
});

let tween, tween2;

const { promise } = useWaitForPageTransitionEnd();

onMounted(async () => {
  if (!canvas.value) {
    return;
  }
  await promise.value;

  const context = canvas.value.getContext("2d");

  canvas.value.width = 1158;
  canvas.value.height = 770;

  // const frameCount = 43;
  const frameCount = 72;
  const currentFrame = (index) =>
    `/images/homepage/sequence/resized/comp3_${(index + 1).toString().padStart(5, "0")}.png`;

  const images = [];
  const sequence = {
    frame: 0,
    currentFrame: -1,
  };

  for (let i = 0; i < frameCount; i++) {
    const img = new Image();
    img.src = currentFrame(i);
    images.push(img);
  }

  tween = gsap.to(sequence, {
    frame: frameCount - 1,
    snap: "frame",
    ease: "none",
    scrollTrigger: {
      scrub: 0.5,
      trigger: canvas.value,
      start: "top 50%",
      end: "bottom 30%",
      toggleActions: "play none none none",
    },
    // duration: 3,
    onUpdate: render, // use animation onUpdate instead of scrollTrigger's onUpdate
  });

  /*tween2 = gsap.to(parent.value, {
    scrollTrigger: {
      trigger: parent.value,
      scrub: true,
      start: "top 80%",
      end: "bottom 10%",
    },
    yPercent: 25,
  });*/

  images[0].onload = render;

  function render() {
    if (sequence.frame === sequence.currentFrame) {
      return;
    }

    sequence.currentFrame = sequence.frame;

    context.clearRect(0, 0, canvas.value.width, canvas.value.height);
    context.drawImage(
      images[sequence.frame],
      0,
      0,
      images[sequence.frame].width,
      images[sequence.frame].height,
      0,
      0,
      canvas.value.width,
      canvas.value.height,
    );
  }
});

onBeforeUnmount(() => {
  if (tween) {
    tween.kill();
  }
  /*if (tween2) {
    tween2.kill();
  }*/
  unwatch();
});
</script>

<style scoped lang="scss"></style>
