<template>
  <div class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12">
    <p
      class="z-50 relative col-span-6 mt-[60%] text-right font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-span-4 laptop:col-start-7 laptop:mt-0 laptop:text-[26px]"
    >
      <AnimationsText :text="data?.homePage?.data?.attributes?.fruits_title" type="words" />
      <!--      {{ data?.homePage?.data?.attributes?.fruits_title }}-->
    </p>

    <div
      class="col-span-4 col-start-1 row-start-2 mt-12 flex flex-col space-y-4 laptop:col-span-2 laptop:col-start-4 laptop:mt-20"
    >
      <div
        v-for="(item, index) in data?.homePage?.data?.attributes?.fruits_mini_text"
        class="font-serif font-bold leading-[1.2] text-ng800 laptop:text-[22px]"
        :key="index"
        :class="{
          'laptop:pl-8': index === 0,
          'laptop:pl-4': index === 1,
        }"
      >
        {{ item?.text }}
      </div>
    </div>
    <div class="col-span-6 col-start-1 row-start-3 mt-12 flex flex-wrap justify-center laptop:col-span-12">
      <div
        class="grid w-full grid-cols-6 items-center justify-items-center gap-x-6 gap-y-0 laptop:h-[270px] laptop:grid-cols-12"
      >
        <HomepageBrandsVarietyHome
          v-for="(product, index) in data?.fruitOptions?.data[0]?.attributes?.product_type_options?.data"
          :key="product.id || JSON.stringify(product)"
          :alt="product.attributes?.icon?.data?.attributes?.alternativeText || ''"
          :image="index !== 1 ? product.attributes?.icon?.data?.attributes?.url : ''"
          :name="product.attributes?.name || ''"
          :to="
            localePath({
              name: 'products-type-subtype',
              params: {
                type: data?.fruitOptions?.data[0]?.attributes?.slug as string,
                subtype: product.attributes?.slug as string,
              },
            })
          "
          class="col-span-3 w-[80%] justify-self-start laptop:col-span-2 laptop:h-auto laptop:w-full"
          :class="{
            'col-span-3 col-start-1 w-[80%] justify-self-start laptop:col-start-4 laptop:ml-0 ': index === 0,
            'col-span-3 col-start-4 w-[80%] justify-self-end laptop:col-start-8 laptop:mr-0  ': index === 1,
          }"
          ref="apricotHolder"
          :aria-label="product.attributes?.name || ''"
        />
      </div>
    </div>
    <div class="col-span-6 row-start-4 mt-12 flex flex-col laptop:col-start-3 laptop:col-end-11">
      <p class="leading-[1.8] text-n300 laptop:text-[18px]">
        <!--        {{ data?.homePage?.data?.attributes?.fruits_paragraph }}-->
        <AnimationsText :text="data?.homePage?.data?.attributes?.fruits_paragraph" type="lines" />
      </p>
      <NextBtn
        class="mt-[33px] ml-auto"
        :text="`${$t('more_about_fruit_product_options')}`"
        :to="
          localePath({
            name: 'products-type',
            params: { type: data?.fruitOptions?.data[0].attributes?.slug as string },
          })
        "
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { HomepageDataKey } from "~/symbols/symbols";

const data = inject(HomepageDataKey);

const emit = defineEmits(["apricot-holder-mounted"]);
const { t: $t } = useI18n();

const localePath = useLocalePath();
const apricotHolder = ref<{ button: { $el: HTMLElement } }[]>([]);

onMounted(() => {
  if (apricotHolder.value && apricotHolder.value[1]) {
    emit("apricot-holder-mounted", apricotHolder.value[1].button.$el);
  }
});
</script>
<style lang="scss" scoped></style>
