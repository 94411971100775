<template>
  <div class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12">
    <p
      class="col-span-6 col-start-1 mb-8 font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-start-3 laptop:col-end-7 laptop:mb-12 laptop:text-[26px]"
    >
      <!--      {{ data?.homePage?.data?.attributes?.social_title }}-->
      <AnimationsText :text="data?.homePage?.data?.attributes?.social_title" type="words" />
    </p>
    <div
      class="col-start-1 col-end-7 grid w-full grid-cols-6 justify-items-center gap-6 gap-y-10 laptop:col-start-1 laptop:col-end-13 laptop:grid-cols-12"
    >
      <HomepageSocialAwarenessIcon
        v-for="(item, index) in items"
        :key="item.id"
        ref="socialAwarenessIcons"
        :item="item"
        class="col-span-4 col-start-2 w-full laptop:col-span-2 laptop:w-full"
        :class="{
          //make items go col-start-3 , col-start-6, col-start-9 repeating using index modulo
          'laptop:col-start-3': index % 3 === 0,
          'laptop:col-start-6': index % 3 === 1,
          'laptop:col-start-9': index % 3 === 2,
        }"
      />
    </div>

    <NextBtn
      class="col-span-6 col-start-1 mt-6 justify-center laptop:col-span-4 laptop:col-start-5 laptop:mt-8"
      :to="localePath({ name: 'company' })"
      :text="`${$t('see_our_company_ethics')}`"
    />
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { useLocaleChanging } from "~~/stores/global";
import { HomepageSocialAwarenessIcon } from "#components";
import { HomepageDataKey } from "~/symbols/symbols";

const data = inject(HomepageDataKey);

const localeChangingStore = useLocaleChanging();
const { t: $t } = useI18n();

const localePath = useLocalePath();

const items = [
  {
    id: 1,
    img: "/icons/eighty-percent.svg",
    title: $t("locally-produced-sourced-ingredients"),
  },
  {
    id: 2,
    img: "/icons/education.svg",
    title: $t("educational-contribution-production-line-open-to-school-visits"),
  },
  {
    id: 3,
    img: "/icons/community.svg",
    title: $t("actions-to-support-local-community-and-education"),
  },
];

const socialAwarenessIcons = ref<InstanceType<typeof HomepageSocialAwarenessIcon> | null>(null);

const tweens: Array<GSAPTween> = [];

onMounted(() => {
  watch(
    () => localeChangingStore.pageTransitionEnded,
    (newValue) => {
      if (newValue) {
        gsap.utils.toArray<{ icon: HTMLElement }>(socialAwarenessIcons.value).forEach((el, index) => {
          tweens.push(
            gsap.from(el.icon, {
              scrollTrigger: {
                trigger: el.icon,
                start: "center 100%",
                end: "bottom top",
                scrub: false,
                //repeat every time
                toggleActions: "play none none none",
              },
              opacity: 0,
              delay: 0.2 * index,
              ease: "ease4",
              duration: 0.5,
            }),
          );
        });
      }
    },
    { immediate: true },
  );
  //fade in the icons one by one without scrolltrigger
});

onBeforeUnmount(() => {
  tweens.forEach((tween) => tween.kill());
  tweens.length = 0;
});
</script>
<style lang="scss" scoped></style>
