<template>
  <div class="apricot-branch pointer-events-none relative flex h-full w-full select-none">
    <img
      src="/images/apricot-branch.png"
      class="absolute -top-[calc(14%)] right-0 z-10 rotate-[12deg] laptop:-top-[calc(14%_+_144px)]"
      ref="apricotBranchDom"
      id="apricot-branch"
      alt="apricot-branch-animation"
    />
    <img
      src="/images/apricot-leaves.png"
      class="absolute -top-[calc(14%)] right-0 rotate-[12deg] laptop:-top-[calc(14%_+_144px)] z-40"
      ref="apricotBranchDom2"
      id="apricot-branch-2"
      alt="apricot-branch-animation"
    />
    <img
      src="/images/apricot-slice.png"
      class="pointer-events-none absolute top-[155px] right-[275px] z-30 select-none object-contain"
      id="apricot-slice"
      ref="apricotSliceDom"
      alt="apricot-branch-animation"
    />
    <img
      src="/images/apricot-big.png"
      class="absolute -top-[calc(14%)] right-0 rotate-[12deg] laptop:-top-[calc(14%_+_144px)] z-30"
      id="apricot"
      ref="apricotDom"
      alt="apricot-branch-animation"
    />
    <div
      class="pointer-events-none absolute top-[155px] right-[275px] z-20 select-none object-contain"
      ref="dummyApricotSliceDom"
    />
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useElementBounding } from "@vueuse/core";

import { useLocaleChanging } from "~~/stores/global";

const localeChangingStore = useLocaleChanging();

const props = defineProps<{
  apricotHolder: HTMLElement | null;
}>();

const apricotSliceDom = ref<HTMLElement | null>(null);
const dummyApricotSliceDom = ref<HTMLElement | null>(null);

const apricotDom = ref<HTMLElement | null>(null);
const apricotBranchDom = ref<HTMLElement | null>(null);
const apricotBranchDom2 = ref<HTMLElement | null>(null);

onMounted(() => {
  const unwatch = watch(
    [() => localeChangingStore.pageTransitionEnded, () => props.apricotHolder],
    ([pageTransitionEnded, apricotHolder]) => {
      if (pageTransitionEnded === true && apricotHolder !== null) {
        initAnimation(apricotHolder as HTMLElement);
        unwatch();
      }
    },
    { immediate: true },
  );
});

// function initAnimation(apricotHolder: HTMLElement) {
//   const tl = gsap.timeline({
//     scrollTrigger: {
//       trigger: "#apricot-branch",
//       start: "40% center",
//       end: "center top",
//       scrub: 2,
//     },
//   });

//   const targetElement = apricotHolder;
//   const targetWidth = targetElement?.clientWidth;
//   const targetHeight = targetElement?.clientHeight;
//   const targetOffset = targetElement?.getBoundingClientRect();
//   const apricotSlice = apricotSliceDom.value as HTMLElement;

//   apricotSlice.style.width = `${targetWidth * 0.6}px`;
//   apricotSlice.style.height = `${targetHeight * 0.6}px`;

//   const apricotSliceWidth = apricotSlice.clientWidth;
//   const apricotSliceHeight = apricotSlice.clientHeight;
//   const apricotSliceOffset = apricotSlice.getBoundingClientRect();
//   tl.to("#apricot-slice", {
//     x: targetOffset.left - apricotSliceOffset.left + targetWidth / 2 - apricotSliceWidth / 2,
//     y: targetOffset.top - apricotSliceOffset.top + targetHeight / 2 - apricotSliceHeight / 2,
//     rotation: 295,
//   });
// }

let tl: gsap.core.Timeline | null = null;
let parallaxTl: gsap.core.Timeline | null = null;
const mm = gsap.matchMedia();

function initAnimation(apricotHolder: HTMLElement) {
  tl = gsap.timeline({
    scrollTrigger: {
      trigger: "#apricot-branch",
      start: "40% center",
      end: "center top",
      invalidateOnRefresh: true,

      scrub: 1.8,
    },
  });

  const apricotSliceEl = apricotSliceDom.value as HTMLElement;
  const dummyApricotSliceEl = dummyApricotSliceDom.value as HTMLElement;

  const {
    width: apricotSliceWidth,
    height: apricotSliceHeight,
    left: apricotSliceLeft,
    top: apricotSliceTop,
  } = useElementBounding(dummyApricotSliceEl);
  const {
    width: targetWidth,
    height: targetHeight,
    left: targetLeft,
    top: targetTop,
  } = useElementBounding(apricotHolder);

  watch(
    [() => targetWidth.value, () => targetHeight.value],
    ([targetWidth, targetHeight]) => {
      apricotSliceEl.style.width = `${targetWidth * 0.6}px`;
      apricotSliceEl.style.height = `${targetHeight * 0.6}px`;

      dummyApricotSliceEl.style.width = `${targetWidth * 0.6}px`;
      dummyApricotSliceEl.style.height = `${targetHeight * 0.6}px`;
    },
    { immediate: true },
  );

  tl.to("#apricot-slice", {
    x: () => targetLeft.value - apricotSliceLeft.value + targetWidth.value / 2 - apricotSliceWidth.value / 2,
    y: () => targetTop.value - apricotSliceTop.value + targetHeight.value / 2 - apricotSliceHeight.value / 2,
    rotation: 310,
  });
  mm.add("(min-width: 1024px)", () => {
    parallaxTl = gsap.to(
      [apricotBranchDom.value as HTMLElement, apricotBranchDom2.value as HTMLElement, apricotDom.value as HTMLElement],
      {
        scrollTrigger: {
          trigger: apricotBranchDom.value as HTMLElement,
          start: "40% center",
          end: "200% top",
          scrub: true,
          // once: true,
        },
        yPercent: 40,
        ease: "none",
      },
    );
  });

  /*gsap.to(apricotBranchDom2.value as HTMLElement, {
    scrollTrigger: {
      trigger: apricotBranchDom.value as HTMLElement,
      start: "40% center",
      end: "200% top",
      scrub: true,
      // once: true,
    },
    yPercent: 40,
    ease: "none",
  });*/
}

onBeforeUnmount(() => {
  if (tl) {
    tl.kill();
    // ScrollTrigger.killAll();
  }

  if (parallaxTl) {
    parallaxTl?.kill();
  }

  mm.revert();
});
</script>
<style lang="scss" scoped></style>
