<template>
  <main>
    <GridsOutterGrid class="relative overflow-hidden">
      <img
        src="/images/backgrounds/home_bg.png"
        ref="homeBg"
        alt="background"
        class="pointer-events-none absolute left-0 top-[50vh] z-10 h-[calc(100%_+_20%)] w-full select-none object-cover"
      />
      <section class="full-height-intro z-20 col-span-3 col-start-1 row-start-1 flex w-full">
        <HomepageVideo />
      </section>
      <section class="z-20 col-span-1 col-start-2 row-start-2 mt-10">
        <HomepageWorkWithUsSection />
      </section>
      <HomepageOliveBranchAnimation class="col-span-3 col-start-1 row-start-2" :olive-holder="oliveHolder" />
      <SectionSet contained class="z-40">
        <HomepageFinestEarthSection @olive-holder-mounted="(value) => (oliveHolder = value)" />
      </SectionSet>
      <HomepageApricotBranchSection class="col-span-3 col-start-1 row-start-4" :apricot-holder="apricotHolder" />
      <SectionSet contained class="row-start-4">
        <HomepageInnovatingInIndustrySection @apricot-holder-mounted="(value) => (apricotHolder = value)" />
      </SectionSet>
      <SectionSet contained class="z-20">
        <HomepageCustomizeYourProductSection />
      </SectionSet>
      <div class="z-20 col-span-1 col-start-2" ref="trigger">
        <SectionSet contained class="enviroment-bg">
          <div class="enviroment-bg-container">
            <img src="/images/backgrounds/home_soil_hands.jpg" />
            <img src="/images/backgrounds/home_soil_hands_color.jpg" class="overlayImage" ref="overlayImage" />
          </div>
          <HomepageEnviromentSection />
        </SectionSet>
      </div>
      <SectionSet contained class="z-20">
        <HomepageEnviromentalResponsibilitySection />
      </SectionSet>
      <SectionSet contained is-last class="z-20 tablet:!mt-[30rem]">
        <HomepageActionsOfEnviromentalSection />
      </SectionSet>
    </GridsOutterGrid>
  </main>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useWaitForPageTransitionEnd } from "~~/composables/waitPageTransition";
import { HomepageDataKey } from "~/symbols/symbols";
const { locale, finalizePendingLocaleChange } = useI18n();

await finalizePendingLocaleChange();

const { data: homePageData } = await useAsyncGql({
  operation: "HomePage",
  variables: { locale: locale.value, limit: 6 },
  options: {
    lazy: false,
  },
});

provide(HomepageDataKey, homePageData);

const { promise } = useWaitForPageTransitionEnd();

const apricotHolder = ref(null);
const oliveHolder = ref(null);
const trigger = ref<HTMLElement | null>(null);
const homeBg = ref<HTMLImageElement | null>(null);
const overlayImage = ref<HTMLImageElement | null>(null);

const pageTitle = ref<string | null>(null);

watch(
  homePageData,
  (newVal) => {
    if (newVal) {
      if (newVal.homePage?.data?.attributes?.seo?.metaTitle) {
        pageTitle.value = newVal.homePage?.data?.attributes?.seo?.metaTitle;
      }
      useHead({
        title: () => pageTitle.value,
      });

      useServerSeoMeta({
        title: () => `${newVal?.homePage?.data?.attributes?.seo?.metaTitle}`,
        ogTitle: () => `${newVal?.homePage?.data?.attributes?.seo?.metaTitle}`,
        description: () => `${newVal?.homePage?.data?.attributes?.seo?.metaDescription}`,
        ogDescription: () => `${newVal?.homePage?.data?.attributes?.seo?.metaDescription}`,
        ogImage: () => `${newVal?.homePage?.data?.attributes?.seo?.metaImage?.data?.attributes?.url}`,
      });
    }
  },
  { immediate: true },
);

onMounted(() => {
  initAnimation();
});

const tweens: Array<GSAPTween> = [];
async function initAnimation() {
  await promise.value;

  if (homeBg.value) {
    tweens.push(
      gsap.to(homeBg.value, {
        scrollTrigger: {
          trigger: homeBg.value,
          scrub: 1,
          start: "top top",
          end: "center center",
        },
        yPercent: -15,
      }),
    );
  }

  if (trigger.value) {
    tweens.push(
      gsap.to(trigger.value, {
        scrollTrigger: {
          trigger: trigger.value,
          scrub: 2,
          start: "top 90%",
          end: "bottom 30%",
        },
        "--enviroment-opacity": 1,
      }),
    );
  }

  if (overlayImage.value) {
    tweens.push(
      gsap.to(overlayImage.value, {
        scrollTrigger: {
          trigger: overlayImage.value,
          scrub: 0.5,
          start: "top 30%",
          end: "bottom 30%",
        },
        opacity: 1,
      }),
    );
  }
}

onBeforeUnmount(() => {
  tweens.forEach((tween) => tween.kill());
  tweens.length = 0;
});
</script>

<style lang="scss" scoped>
.enviroment-bg {
  position: relative;
  .enviroment-bg-container {
    content: "";
    position: absolute;
    left: -30%;
    top: 0;
    width: 125%;
    height: 0;
    border-radius: 50%;
    //background-image: url("/images/backgrounds/home_soil.png");
    //background-color: #f00;
    //background-repeat: no-repeat;
    //background-position: center;
    //background-size: 100%;
    transform: 1;
    opacity: 1;
    transform-origin: center 70%;
    z-index: -1;
    padding-top: 125%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 50%;

    &.overlayImage {
      opacity: 0;
    }
  }

  @media (min-width: theme("screens.tablet")) {
    .enviroment-bg-container {
      left: -30%;
      top: 0%;
      width: 100%;
      padding-top: 100%;
    }
  }

  @media (min-width: theme("screens.laptop")) {
    .enviroment-bg-container {
      left: 0;
      top: 50%;
      width: 50%;
      padding-top: 50%;
      transform: translateY(-55%);
    }
  }
}
</style>
