<template>
  <div class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12">
    <p
      class="col-span-6 font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-span-4 laptop:col-start-3 laptop:text-[26px]"
    >
      <AnimationsText :text="data?.homePage?.data?.attributes?.olives_title" type="letters" />
      <!--      {{ data?.homePage?.data?.attributes?.olives_title }}-->
    </p>
    <div
      class="col-span-4 col-start-3 row-start-2 mt-12 flex flex-col space-y-4 laptop:col-span-2 laptop:col-start-9 laptop:mt-20"
    >
      <div
        v-for="(item, index) in data?.homePage?.data?.attributes?.olives_mini_text"
        class="font-serif font-bold leading-[1.2] text-ng800 laptop:text-[22px]"
        :key="index"
        :class="{
          'laptop:pl-4': index === 1,
          'laptop:pl-8': index === 2,
        }"
      >
        {{ item?.text }}
      </div>
    </div>
    <div
      class="col-start-1 col-end-7 row-start-3 mt-12 grid w-full grid-cols-12 items-center gap-6 justify-items-center gap-y-0 laptop:col-end-13 laptop:h-[270px] laptop:grid-cols-12 laptop:gap-6"
    >
      <HomepageBrandsVarietyHome
        v-for="(product, index) in productTypeOptions"
        :key="product.id || JSON.stringify(product)"
        :alt="product.attributes?.icon?.data?.attributes?.alternativeText || ''"
        :image="index !== 1 ? product.attributes?.icon?.data?.attributes?.url : ''"
        :name="product.attributes?.name || ''"
        :to="
          localePath({
            name: 'products-type-subtype',
            params: {
              type: data?.oliveOptions?.data[0]?.attributes?.slug as string,
              subtype: product.attributes?.slug as string,
            },
          })
        "
        :aria-label="product.attributes?.name || ''"
        class="h-auto w-[80%] laptop:w-full col-span-6 laptop:col-span-2"
        ref="oliveHolderArray"
        :class="{
          'col-start-4 laptop:col-start-3 mb-4 w-[60%] laptop:mb-0': index === 0,
          'col-start-1 laptop:col-start-6 justify-self-start laptop:ml-0 olive-holder': index === 1,
          'col-start-7 laptop:col-start-9 justify-self-end laptop:mr-0': index === 2,
        }"
      />
    </div>
    <div class="col-start-1 col-end-7 row-start-4 mt-12 laptop:col-start-3 laptop:col-end-11">
      <p class="leading-[1.8] text-n300 laptop:text-[18px]">
        <!--        {{ data?.homePage?.data?.attributes?.olives_paragraph }}-->
        <AnimationsText :text="data?.homePage?.data?.attributes?.olives_paragraph" type="lines" />
      </p>

      <NextBtn
        class="mt-[33px]"
        :text="`${$t('more_about_olive_product_options')}`"
        :to="
          localePath({
            name: 'products-type',
            params: { type: data?.oliveOptions?.data[0]?.attributes?.slug as string },
          })
        "
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { HomepageDataKey } from "~/symbols/symbols";
import { useParallax } from "~/composables/useParallax";
import { useWaitForPageTransitionEnd } from "~~/composables/waitPageTransition";
const { promise } = useWaitForPageTransitionEnd();

const data = inject(HomepageDataKey);

const container = ref<HTMLElement | null>(null);
const container1 = ref<HTMLElement | null>(null);

async function initAnimation() {
  await promise.value;
  useParallax(container.value);
  // useParallax(container1.value);
}
const emit = defineEmits(["olive-holder-mounted"]);
const { t: $t } = useI18n();

const localePath = useLocalePath();

const oliveHolderArray = ref<{ button: { $el: HTMLElement } }[]>([]);

onMounted(() => {
  if (oliveHolderArray.value && oliveHolderArray.value[1]) {
    emit("olive-holder-mounted", oliveHolderArray.value[1].button.$el);
  }
  initAnimation();
});

const productTypeOptions = computed(() => {
  if (data?.value?.oliveOptions?.data[0]?.attributes?.product_type_options?.data instanceof Array) {
    return data?.value?.oliveOptions?.data[0]?.attributes?.product_type_options?.data.slice(0, 3);
  } else {
    return [];
  }
});
</script>
