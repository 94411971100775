<template>
  <div
    class="flex w-full items-center justify-center space-x-[21px] tablet:space-x-[40px] laptop:space-x-[70px]"
    ref="container2"
  >
    <img
      src="/images/certifications/iso-certified.svg"
      :alt="$t('iso-certified')"
      width="111"
      height="111"
      class="parallax h-full w-full max-w-[64px] laptop:max-w-[111px]"
    />
    <img
      src="/images/certifications/olives-greek-product.svg"
      alt=""
      width="111"
      height="111"
      class="parallax h-full w-full max-w-[64px] laptop:max-w-[111px]"
    />

    <img
      src="/images/certifications/ifs-certified.svg"
      alt=""
      width="111"
      height="111"
      class="parallax h-full w-full max-w-[64px] laptop:max-w-[111px]"
    />
    <img
      src="/images/certifications/greek-product.svg"
      alt=""
      width="111"
      height="111"
      class="parallax h-full w-full max-w-[64px] laptop:max-w-[111px]"
    />
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { useWaitForPageTransitionEnd } from "~~/composables/waitPageTransition";

const { promise } = useWaitForPageTransitionEnd();

const { t: $t } = useI18n();

const container2 = ref<HTMLElement>();

const tweens: Array<GSAPTimeline> = [];

async function initAnimation() {
  await promise.value;

  if (container2.value) {
    gsap.utils.toArray<{ el: HTMLElement }>(".parallax").forEach((el, index) => {
      tweens.push(
        gsap
          .timeline({
            scrollTrigger: {
              trigger: container2.value,
              start: "center 90%",
              scrub: false,
              toggleActions: "play none none none",
            },
          })
          .fromTo(
            el,
            {
              y: index * 50 + 50,
              opacity: 0,
            },
            {
              y: 0,
              opacity: 1,
              duration: 1.5,
              ease: "power4.out",
            },
          ),
      );
    });
  }
}

onMounted(() => {
  initAnimation();
});

onBeforeUnmount(() => {
  tweens.forEach((tween) => tween.kill());
  tweens.length = 0;
});
</script>
<style lang="scss" scoped></style>
