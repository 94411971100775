<template>
  <div class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12">
    <p
      class="col-span-6 col-start-1 font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-start-3 laptop:col-end-10 laptop:text-[26px]"
    >
      <!--      {{ data?.homePage?.data?.attributes?.pl_title }}-->
      <AnimationsText :text="data?.homePage?.data?.attributes?.pl_title" type="words" />
    </p>
    <NextBtn
      class="col-span-6 row-start-2 mt-2 laptop:col-start-3"
      :text="`${$t('see_priva_label_options')}`"
      :to="localePath({ name: 'for-business-private-label' })"
    />

    <div class="col-span-6 mt-[33px] flex flex-col laptop:col-start-3 laptop:col-end-11">
      <p class="leading-[1.8] text-n300 laptop:text-[18px]">
        <!--        {{ data?.homePage?.data?.attributes?.pl_paragraph }}-->
        <AnimationsText :text="data?.homePage?.data?.attributes?.pl_paragraph" type="lines" />
      </p>
    </div>
    <div
      class="col-span-6 row-start-3 mt-12 flex flex-col bg-[#EBDED8] px-6 py-4 laptop:col-start-5 laptop:col-end-9 laptop:row-start-4 relative h-0 pt-[67.55%]"
    >
      <img src="/images/jam-jar.png" ref="jar" class="object-contain absolute w-full h-full top-0 left-0" alt="" />
    </div>
    <div class="col-span-6 row-start-5 mt-25 flex flex-col laptop:col-start-3 laptop:col-end-11 laptop:mt-36">
      <p
        class="col-span-6 col-start-1 self-end text-right font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-start-3 laptop:col-end-10 laptop:text-[26px]"
      >
        <!--        {{ data?.homePage?.data?.attributes?.brands_title }}-->
        <AnimationsText :text="data?.homePage?.data?.attributes?.brands_title" type="words" />
      </p>
    </div>
    <div
      class="col-start-1 col-end-7 mb-6 mt-12 grid w-full grid-cols-6 justify-items-center gap-6 gap-y-10 pb-6 laptop:col-start-1 laptop:col-end-13 laptop:grid-cols-12"
    >
      <!--      <HomepageBrandsVarietyHome
        v-for="(product, index) in data?.featuredProducts?.data"
        :key="product.id || JSON.stringify(product)"
        :alt="product.attributes?.image?.data?.attributes?.alternativeText || ''"
        :image="product.attributes?.image?.data?.attributes?.url"
        :name="product.attributes?.product_brand?.data?.attributes?.name || ''"
        :to="
          localePath({
            name: 'brands-product',
            params: { product: product.attributes?.slug || '' },
            query: { id: product.id },
          })
        "
        :title="product.attributes?.name || ''"
        overflown
        class="col-span-3 h-auto w-[80%] laptop:col-span-2 laptop:w-full"
        :class="{
          'laptop:col-start-3': index % 3 === 0,
          'laptop:col-start-6': index % 3 === 1,
          'laptop:col-start-9': index % 3 === 2,
        }"
      />-->
      <HomepageBrandsVarietyHome
        alt=""
        image="/images/homepage/brands/delphi.png"
        name="DELPHI"
        :to="
          localePath({
            name: 'brands',
            query: {
              brand: 'delphi',
            },
          })
        "
        title="Delphi Olives & Fruits"
        overflown-more
        class="col-span-3 h-auto w-[80%] laptop:col-span-2 laptop:w-full laptop:col-start-3"
      />
      <HomepageBrandsVarietyHome
        alt=""
        image="/images/homepage/brands/ionis.png"
        name="IONIS"
        :to="
          localePath({
            name: 'brands',
            query: {
              brand: 'ionis',
            },
          })
        "
        title="Ionis Olives"
        overflown-more
        class="col-span-3 h-auto w-[80%] laptop:col-span-2 laptop:w-full laptop:col-start-6"
      />
      <HomepageBrandsVarietyHome
        alt=""
        image="/images/homepage/brands/smile-olives.png"
        name="SMILE OLIVES"
        :to="
          localePath({
            name: 'brands',
            query: {
              brand: 'smile-olives',
            },
          })
        "
        title="Smile Olives"
        overflown-more
        class="col-span-3 h-auto w-[80%] laptop:col-span-2 laptop:w-full laptop:col-start-9"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { useLocaleChanging } from "~~/stores/global";
import { HomepageDataKey } from "~/symbols/symbols";

const data = inject(HomepageDataKey);

const localeChangingStore = useLocaleChanging();

const jar = ref<HTMLElement>();
const localePath = useLocalePath();
const { t: $t } = useI18n();

let tl, tl2;

onMounted(() => {
  watch(
    () => localeChangingStore.pageTransitionEnded,
    (pageTransitionEnded) => {
      if (pageTransitionEnded === true) {
        tl = gsap.timeline({
          scrollTrigger: {
            trigger: jar.value,
            start: "top 60%",
            end: "bottom top",
            scrub: 2,
          },
        });

        tl2 = gsap.timeline({
          scrollTrigger: {
            trigger: jar.value,
            start: "top bottom",
            end: "bottom center",
            scrub: 2,
          },
        });

        tl.to(jar.value as HTMLElement, {
          y: -50,
          duration: 1,
        });
        tl2.fromTo(
          jar.value as HTMLElement,
          {
            //scale
            scale: 0.6,
          },
          {
            scale: 1,
          },
        );
      }
    },
    { immediate: true },
  );
});

onBeforeUnmount(() => {
  if (tl) {
    tl.kill();
  }
  if (tl2) {
    tl2.kill();
  }
});
</script>
<style lang="scss" scoped></style>
