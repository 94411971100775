<template>
  <div class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12" ref="responsibilityItemsParent">
    <p
      class="col-span-6 col-start-1 self-end text-right font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-start-7 laptop:col-end-11 laptop:text-[26px]"
    >
      <!--      {{ data?.homePage?.data?.attributes?.enviroment_2_title }}-->
      <AnimationsText :text="data?.homePage?.data?.attributes?.enviroment_2_title" type="words" />
    </p>

    <div
      class="col-span-6 col-start-1 mt-8 flex flex-col space-y-2.5 laptop:col-span-4 laptop:col-start-3 laptop:mt-12 laptop:space-y-5"
    >
      <HomepageEnviromentalResponsibilityItem v-for="item in items" :key="item.id" :item="item" />
    </div>
    <div
      class="relative col-span-6 col-start-1 mt-8 flex h-0 justify-center pt-[100%] laptop:col-span-5 laptop:col-start-7 laptop:mt-12"
    >
      <HomepageEnviromentalResponsibilityAnimation
        speed="2"
        class="parallaxLeaf absolute inset-0 col-span-2 col-start-5 transform justify-self-center"
        alt=""
        ref="leaf"
        loading="lazy"
      />
      <img
        v-for="olive in olives"
        :key="olive.id"
        :src="olive.image"
        :speed="olive.speed"
        :style="`left: ${Math.random() * 100}%;`"
        class="absolute bottom-0 -translate-x-1/2 transform justify-self-center"
        :alt="$t('olive')"
        ref="parallax"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { gsap } from "gsap";
import { useLocaleChanging } from "~~/stores/global";
import { HomepageDataKey } from "~/symbols/symbols";

const data = inject(HomepageDataKey);

const localeChangingStore = useLocaleChanging();
const { t: $t } = useI18n();

const items = [
  {
    id: 0,
    image: "/icons/ri_recycle-fill.svg",
    title: $t("recycling"),
  },
  {
    id: 1,
    image: "/icons/ic_round-water-drop.svg",
    title: $t("waste-water-treatment"),
  },
  {
    id: 2,
    image: "/icons/ri_recycle-fill2.svg",
    title: $t("1mw-recycling-photovoltaic-panel-installation"),
  },
  {
    id: 3,
    image: "/icons/sedex.svg",
    title: $t("sedex-member"),
  },
];

const olives = [
  {
    id: 0,
    image: "/images/olives/olive-home-1.png",
    speed: 8,
  },
  {
    id: 1,
    image: "/images/olives/olive-home-2.png",
    speed: 9,
  },
  {
    id: 2,
    image: "/images/olives/olive-home-3.png",
    speed: 13,
  },
  {
    id: 3,
    image: "/images/olives/olive-home-4.png",
    speed: 10,
  },
];

const parallax = ref<HTMLElement[]>([]);

const leaf = ref<HTMLElement | null>(null);
const responsibilityItemsParent = ref<HTMLElement | null>(null);

const tweens: Array<GSAPTween> = [];
const mm = gsap.matchMedia();

onMounted(() => {
  watch(
    () => localeChangingStore.pageTransitionEnded,
    (newValue) => {
      if (newValue) {
        gsap.utils.toArray<HTMLElement>(parallax.value).forEach((el) => {
          const speed = el.getAttribute("speed") as unknown as number;

          tweens.push(
            gsap.to(el, {
              y: `+=${speed ? speed * -50 : 1 * -50}`,
              ease: "none",
              scrollTrigger: {
                trigger: el,
                start: "center bottom",
                end: "bottom top",
                scrub: 2,
              },
            }),
          );
        });

        if (responsibilityItemsParent.value) {
          mm.add("(min-width: 1024px)", () => {
            tweens.push(
              gsap.to(responsibilityItemsParent.value, {
                scrollTrigger: {
                  trigger: responsibilityItemsParent.value,
                  scrub: true,
                  start: "top 99%",
                  end: "bottom 1%",
                },
                yPercent: 40,
              }),
            );
          });
        }

        if (!leaf.value || !leaf.value.getAttribute) return;
        const leafSpeed = leaf.value.getAttribute("speed") as unknown as number;

        tweens.push(
          gsap.to(leaf.value, {
            y: `+=${leafSpeed ? leafSpeed * -50 : 1 * -50}`,
            ease: "none",
            scrollTrigger: {
              trigger: leaf.value,
              start: "center bottom",
              end: "bottom top",
              scrub: 2,
            },
          }),
        );
      }
    },
    { immediate: true },
  );
});

onBeforeUnmount(() => {
  tweens.forEach((tween) => tween.kill());
  tweens.length = 0;

  mm.revert();
});
</script>
<style lang="scss" scoped></style>
