<template>
  <div class="flex flex-col items-center justify-start text-center" ref="icon">
    <div
      class="flex aspect-square h-auto w-full max-w-[270px] items-center justify-center px-3 laptop:max-h-[246px] laptop:max-w-[246px]"
    >
      <img
        class="aspect-square h-auto w-full object-contain laptop:p-[12%]"
        :src="props.item.img"
        :alt="props.item.title"
      />
    </div>
    <div class="mt-4 laptop:mt-2 text-ng800 font-medium">{{ props.item.title }}</div>
  </div>
</template>
<script setup lang="ts">
const props = defineProps<{
  item: {
    img: string;
    title: string;
  };
}>();

const icon = ref<HTMLElement | null>(null);

defineExpose({
  icon,
});
</script>
<style lang="scss" scoped></style>
