<template>
  <div class="relative grid w-full grid-cols-6 gap-6 gap-y-0 laptop:grid-cols-12">
    <p
      class="col-span-6 col-start-1 mt-[89px] mb-8 font-serif text-lg font-bold leading-[1.4] text-ng800 laptop:col-start-3 laptop:col-end-6 laptop:mb-12 laptop:text-[26px]"
    >
      <!--      {{ data?.homePage?.data?.attributes?.enviroment_1_title }}-->
      <AnimationsText :text="data?.homePage?.data?.attributes?.enviroment_1_title" type="words" />
    </p>
    <div class="col-span-5 col-start-1 laptop:col-span-4 laptop:col-start-3 pt-[77.12%]">
      <!--      <img
        src="/images/temporary/soil.jpg"
        width="564"
        height="435"
        :alt="$t('soil')"
        class="h-auto w-full object-contain"
        loading="lazy"
      />-->
    </div>
    <div class="col-span-6 col-start-1 mt-8 flex flex-col laptop:col-span-3 laptop:col-start-8 laptop:mt-0">
      <p class="leading-[1.8] text-n300 laptop:text-[18px]">
        <!--        {{ data?.homePage?.data?.attributes?.enviroment_1_paragraph }}-->
        <AnimationsText :text="data?.homePage?.data?.attributes?.enviroment_1_paragraph" type="lines" />
      </p>

      <p
        v-for="(item, index) in data?.homePage?.data?.attributes?.enviroment_mini_text"
        class="mt-8 text-[17px] font-medium leading-5 text-ng800"
        :key="index"
        :class="{
          'laptop:mt-[88px]': index === 0,
          'laptop:mt-[55px]': index !== 0,
        }"
      >
        {{ item?.text }}
      </p>
      <NextBtn
        class="col-span-6 row-start-2 mt-6 justify-center laptop:col-start-3 laptop:ml-auto laptop:mt-[73px]"
        :text="`${$t('work_with_us')}`"
        :to="localePath({ name: 'for-business' })"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
import { HomepageDataKey } from "~/symbols/symbols";
const data = inject(HomepageDataKey);

const localePath = useLocalePath();
const { t: $t } = useI18n();
</script>
<style lang="scss" scoped></style>
